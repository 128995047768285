import React from "react";

//Home 
const Home = React.lazy(() =>import("./pages/home"));

//About us
const AboutUs = React.lazy(() =>import("./pages/aboutus"));

//Add money
const Add = React.lazy(()=> import(/* webpackPrefetch: true */ "./pages/add"));
//Send money
const Send = React.lazy(()=> import(/* webpackPrefetch: true */ "./pages/send"));
//Exchange money
const Exchange = React.lazy(()=> import("./pages/exchange"));
//Pay Bills
const PayBills = React.lazy(()=> import("./pages/paybill"));

//Pay School Fees
const PayFees = React.lazy(()=> import("./pages/payfees"));

//Cards
const Cards = React.lazy(()=> import("./pages/cards"));
//Vouchers
const Vouchers = React.lazy(()=> import("./pages/vouchers"));

//Giftcards
const Giftcards = React.lazy(()=> import("./pages/giftcards"));
//Save
const Save = React.lazy(()=>import("./pages/save"));
//Credit
const Credit = React.lazy(()=>import("./pages/credit"));
//FAQa
const Faqs = React.lazy(() =>import("./pages/faqs"))

export {
  Home,
  AboutUs,
  Add,
  Send,
  Exchange,
  PayBills,
  Cards,
  PayFees,
  Vouchers,
  Giftcards,
  Save,
  Credit,
  Faqs
}
