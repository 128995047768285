
export const TRANSLATIONS_ES = {

    "aboutus":{
        "header": "Sobre nosotros",
        "description": "Nsawo Finance Inc. (Número comercial 703066738), en resumen, Nsawo es una subsidiaria y está pendiente de registro de marca registrada de Paytimeafrica Inc. (Número comercial 768086084), que es una empresa de servicios monetarios (MSB) registrada en el Centro de análisis de informes y transacciones financieras de Canadá (FINTRAC). ) con el número de registro M20196957 en la sección de cambio de moneda y transferencia de fondos.",
        "core-values": {
            "header": "Nuestros valores fundamentales",
            "our-family": {
                "header": "Nuestro familia",
                "description": "Nos enorgullecemos de preocuparnos por los intereses de los miembros de nuestra familia, así como de servir los intereses de nuestra plataforma, las partes interesadas y los socios."
            },
            "our-innovation": {
                "header": "Nuestra Innovación",
                "description": "El compromiso con la mejora constante e incesante de nuestros productos, sistemas, procesos y personas sigue siendo el pilar de nuestra estrategia empresarial."
            },
            "our-integrity": {
                "header": "Nuestro integridad",
                "description": "Somos transparentes, éticos, honestos y profesionales en todas nuestras interacciones."
            },
            "our-excellence": {
                "header": "Nuestro Excelencia",
                "description": "Nuestro deseo de ser responsables y brindar lo mejor para nuestra comunidad es clave, el legado de nuestra plataforma se mantendrá fuerte."
            }
        },
        "our-vision": {
            "header": "Nuestra visión",
            "description": "Ser la plataforma digital líder en la distribución de productos y servicios financieros dirigidos a personas afrodescendientes y la comunidad negra en todo el mundo."
        },
        "our-mission": {
            "header": "Nuestra misión",
            "description": "Nuestra misión es empoderar a las familias de personas afrodescendientes y la comunidad negra de todo el mundo con productos y servicios financieros que mejoren sus vidas a través de nuestra plataforma fintech."
        },
        "leadership": {
            "header": "El liderazgo de Nsawo",
            "description": "Reúnase con el equipo de Nsawo Finance Inc C que piensa fuera de la caja para hacer de las comunidades negras un lugar mejor para la libertad financiera"
        }
    },
    "seo": {
        "home": {
            "title": "Nsawo - La plataforma financiera libre de inmigrantes sin fronteras",
            "description": "¡Un inmigrante! eres ? sí. Desde transferencias transfronterizas hasta seguros e inversiones, la aplicación Nsawo facilita el movimiento de dinero o el envío, pago, solicitud, recepción, intercambio de monedas fiduciarias y criptomonedas a nivel mundial a una micro fracción de costo.",
        },
        "aboutus": {
            "title": "Sobre nosotros",
            "description": "Nsawo Finance Inc. (Número comercial 703066738), en resumen, Nsawo es una subsidiaria y está pendiente de registro de marca registrada de Paytimeafrica Inc. (Número comercial 768086084), que es una empresa de servicios monetarios (MSB) registrada en el Centro de análisis de informes y transacciones financieras de Canadá (FINTRAC). ) con el número de registro M20196957 en la sección de cambio de moneda y transferencia de fondos.",
        }
        
    },
    "header": {
        "menus": {
            "features":'Fonctionnalités',
            "banking": 'Bancaire',
            "company": 'Compagnie',
            "business": 'For Business',
            "help": 'Aide',
        },
        "submenus":{
            "add": "Ajouter de l'argent",
            "request": "Demande de l'argent",
            "send": "Envoyer de l'argent",
            "receive": "Recevoir de l'argent",
            "exchange": "Échange de devises",
            "bill-payments": "Payer les factures",
            "airtime": "Acheter du temps d'antenne",
            "vcard": "Carte virtuelle",
            "pay-school-fees": "Payer les frais de scolarité",
            "request_statement":"Demande de relevé",
            "referal":"Parrainez et gagnez (programme de parrainage)",
            "save": "Sauvegarder",
            "credit": "Crédit",
            "budget-planner": "Planificateur de budget",
            "insurance": "Assurance",
            "investment": "Investissement",
            "business_account": "Business Account",
            "business_card": "Platinum Card",
            "business_payouts": "Customer Payouts",
            "business_pricing": "Pricing",
            "blog": "Blog",
            "press": "dans la presse",
            "join-our-team": "Rejoins notre équipe",
            "investors": "Investisseuses",
            "about-us": "À propos de nous",
            "get-help": "Obtenir de l'aide",
            "faqs": "FAQs",
            "guides": "Guides",
            "contact-us" : "Nous contacter",
        }
    },
    "section": {
        "notification-header": "Transferts hybrides sécurisés, rapides, flexibles et fiables",
        "notification-subheader": "De confiance",
        "header": "Sans frontières. Sans fin, sans contact",
        "message" : "Nsawo, una plataforma fintech gratuita para inmigrantes. Hacemos posible pagar, enviar, solicitar, intercambiar y recibir fácilmente monedas fiduciarias a nivel mundial en nuestra plataforma con tecnología blockchain."
    },

    "getstarted_steps": {
        "header" :"Un moyen rapide de vous lancer.",
        "deposit-money-header": "Ajouter de l'argent",
        "deposit-money-description": "Ajoutez facilement des fonds à votre portefeuille via plusieurs canaux tels que VISA, MasterCard, American Express, Discover, compte bancaire ainsi que Mobile Money dans les pays africains",
        "send-money-header": "Envoyer de l'argent",
        "send-money-description": "Transferts d'argent instantanés gratuits entre les portefeuilles Nsawo dans le monde",
        "exchange-money-header": "Monnaie d'échange",
        "exchange-money-description": "Profitez d'un portefeuille électronique multidevises sans frontières qui vous permet d'échanger, de dépenser et d'envoyer de l'argent au meilleur taux du marché de gros.",
        "pay-utility-header": "Payer les services publics",
        "pay-utility-description": "Payez facilement les services publics à travers les frontières à votre convenance",
        "inbox-header": "Boîte de réception d'activité",
        "inbox-description": "Toutes vos activités récentes, derniers dépôts, échanges, tous les transferts et messages de chat directs avec vos connexions - tout à votre coup d'œil dans l'application."
    },


    "features": {
        "header": "Nous sommes futuristes et compétitifs.",
        "group-sending-header": "Envoi groupé",
        "group-sending-description": "Profitez de la fonction d'envoi de groupe imbattable, créez facilement des groupes de votre choix et envoyez de l'argent à vos groupes affiliés tels que des associations, des groupes de la société, des groupes familiaux, des groupes religieux - le tout gratuitement",
        "chat-payment-header": "Paiements par chat",
        "chat-payment-description": "Nous sommes une communauté noire en évolution financière et une plate-forme interactive. Envoyez, payez, demandez et recevez de l'argent facilement via notre service de paiement par chat p2p avec vos amis, vos proches et votre famille - gratuitement",
        "activity-inbox-header": "Boîte de réception d'activité",
        "activity-inbox-description": "Toutes vos activités récentes, derniers dépôts, échanges, tous les transferts et messages de chat directs avec vos connexions - tout à votre coup d'œil dans l'application.",
        "cards-payment-header": "Connexion Essence",
        "cards-payment-description": "Connectez-vous avec nos cartes de débit et virtuelles sans frontières Essence 2FA pour faciliter votre expérience de magasinage en ligne au bout de vos doigts. Invitez vos proches et votre famille à profiter des avantages de la carte essence - payez plus facilement que jamais.",
        "gift-cards-header": "Cartes cadeaux",
        "gift-cards-description": "Vous souhaitez faire un don ou une contribution à l'église avec un reçu fiscal ? Nos cartes-cadeaux Essence vous protègent. ne cherchez plus, vous pouvez maintenant acheter une carte-cadeau de n'importe quel montant dans n'importe quelle devise et l'envoyer. - acheter, envoyer, approuver et échanger à tout moment partout.",
        "payment-vouchers-header": "Bons de paiement",
        "payment-vouchers-description": "Connectez-vous avec le service Essence 2FA Voucher pour envoyer ou recevoir des paiements vers et depuis des tiers comme les plateformes de trading Neobanks, Securities & Forex et bien d'autres - payez facilement n'importe où avec les cartes de bons de paiement Essence",

        "customer-support-header": "Service client",
        "customer-support-description": "Si vous avez des questions ou avez besoin d'aide, notre équipe de service client est à portée de clic en ligne dans l'application - disponible en ligne 24h / 24 et 7j / 7",
    
    },

    "banking": {
        "personal-save-header": "Personal Savings",
        "personal-save-description": "",
        "group-save-header": "Group Savings",
        "group-save-description": "",
        "personal-credit-header": "Personal Credit",
        "personal-credit-description": "",
        "group-credit-header": "Group Credit",
        "group-credit-description": ""
    },

    "partners": {
        "header1" : "Nos partenaires",
        "header2" : "Facilitateur technologique",
        "header3" : "mondial",
        "message1" : "Envoyez, recevez, échangez et négociez des monnaies fiduciaires et cryptographiques où que vous soyez avec",
        "message2" : "Nsawo",
        "message3" : "propulsé par un réseau de valeur perturbateur de catalyseurs de blockchain.",
    },

    "testimonials": {
        "header1" : "Savez-vous ?",
        "header2": "Plus de 1000",
        "header3": "personnes nous aiment",
        "message1": "Rejoignez plus d'un million de personnes envoyant, recevant et échangeant de l'argent en toute sécurité dans le monde entier."
    },

    "joinus": {
        "header1" : "Tu veux nous rendre fiers",
        "message1": "Rejoignez une équipe dont le but ultime est de débloquer des opportunités mondiales pour les Africains et la communauté des peuples noirs.",
        "message2": "Rejoins l'équipe",
    },

    "appstore": {
        "header1" : "Disponible pour votre",
        "header2" : "Smartphones",
        "message" : "Vous êtes à quelques pas pour commencer à profiter de nos services et avantages monétaires.",
        "appstore_message" : "Téléchargez notre application sur Apple ou Google Store",
        "install_message" : "Installez l'application maintenant sur vos téléphones portables",
        "link_message" : "Apprendre encore plus",
        "early_access_join_message_text": "For early access to Nsawo financial platform",
        "early_access_join_text": "Sign up Now"
    },

    "coverage": {
        "header1" : "Profitez de sans frontières, sans contact",
        "header2" : "transferts d'argent.",
        "subheader" : "Nsawo est basé sur Afrocentric mais ouvert à tout le monde dans le monde. ",
        "features" : {
            "countries" :  " Plus d'options pour les pays d'accueil",
            "payout_methods" : " Plus de méthodes de paiement",
            "exchage_rates" : " De meilleurs taux de change",
            "instant_transfer" : " Transferts personnels instantanés",
            "giftcards" : " Envoyez de l'argent via des cartes-cadeaux électroniques",
         },
         "buttonText" : "Apprendre encore plus"
    },

    "footer": {
        "menus": {
            "products": 'Des produits',
            "company": 'Compagnie',
            "help": 'Aider',
            "compliancy": 'Conformité',
        },
        "submenus": {
            "add": "Ajouter de l'argent",
            "send": "Envoyer de l'argent",
            "exchange": "Échange",
            "bill-payment": "Masse salariale",
            "pay-school-fees": "Payer les frais de scolarité",
            "blog": "Blogs",
            "press": "Presse",
            "join-our-team": "Carrières",
            "investors": "Investisseurs",
            "about-us": "À propos de nous",
            "get-help": "Obtenir de l'aide",
            "faqs": "FAQs",
            "guides": "Guides",
            "terms-conditions": "termes et conditions",
            "privacy-policy": "Politique de confidentialité",
            "cookie-policy" : "Politique relative aux cookies",
        },
        "headers": {

            "contact": "Contacter",
        },
        "copyright":{

            "trademark_statement1": "Nsawo est le nom commercial de Paytimeafrica Inc, qui est une entreprise de services monétaires (MSB) enregistrée auprès du Centre d'analyse des transactions et des déclarations financières du Canada (CANAFE) sous les numéros d'enregistrement M 20196957 sous change, transfert de fonds et devises numériques.",
            "trademark_statement2" : "Rejoignez notre famille Nsawo en téléchargeant l'application Nsawo qui dispose d'un portefeuille multi-devises avec des avantages qui incluent des transferts gratuits de Nsawo à Nsawo.",
            "trademark_statement3": "Slogan : Tous vos besoins de transfert de fonds sont satisfaits avec notre application Nsawo. Profitez des avantages des transferts d'argent sécurisés, gratuits et instantanés avec cette plateforme fintech afrocentrique canadienne.",
            "trademark_statement4": "Inscrivez-vous pour un accès anticipé à Nsawo, une plate-forme fintech conçue pour les personnes d'ascendance africaine et tout le monde partout dans le monde. Vous pouvez envoyer de l'argent, demander et recevoir de l'argent ainsi que d'autres fonctionnalités en fonction de votre pays d'origine.",
            "trademark_statement5": "* La carte prépayée Visa / débit MasterCard est détenue et émise par DC Bank en vertu d'une licence de Visa International ou de MasterCard international incorporé. L'utilisation de la carte sera régie par l'accord en vertu duquel elle sera émise. Les marques Visa et MasterCard sont des marques déposées de Visa International et MasterCard international incorporé. Tous les crédits et approbations seront fournis par Paytimeafrica Inc. DC Bank ne fournit aucun crédit ni prêt. Tous les financements et prêts pour ce programme seront fournis par Paytimeafrica Inc. o/a Nsawo. Les approbations sont soumises aux directives de révision et de souscription de Paytimeafrica Inc.",
            
            "trademark_statement6": "* Il n'y a pas de frais pour postuler. Des frais mensuels, des frais de transaction et des intérêts s'appliquent et sont divulgués dans les conditions générales de la carte prépayée.",
            "trademark_statement7": "En utilisant ce site Web, vous comprenez que les informations présentées sont fournies à titre informatif uniquement et acceptez nos conditions générales ainsi que notre politique de confidentialité.",

            
        }
    },
}