export const SHOW_LOADING = "SHOW_LOADING";
export const STOP_LOADING = 'STOP_LOADING';


export const SET_SELECTED_CURRENCIES = "SET_SELECTED_CURRENCIES";
export const SET_SELECTED_CURRENCIES_SUCCESS = "SET_SELECTED_CURRENCIES_SUCCESS";

export const FETCH_EXCHANGE_DATA = "FETCH_EXCHANGE_DATA";
export const FETCH_EXCHANGE_DATA_SUCCESS = "FETCH_EXCHANGE_DATA_SUCCESS";
export const FETCH_EXCHANGE_DATA_FAILED = "FETCH_EXCHANGE_DATA_FAILED";

export const FETCH_CURRENCIES_DATA = "FETCH_CURRENCIES_DATA";
export const FETCH_CURRENCIES_DATA_SUCCESS = "FETCH_CURRENCIES_DATA_SUCCESS";
export const FETCH_CURRENCIES_DATA_FAILED = "FETCH_CURRENCIES_DATA_FAILED";

export const FETCH_EXCHANGE_RATES = "FETCH_EXCHANGE_RATES";
export const FETCH_EXCHANGE_RATES_SUCCESS = "FETCH_EXCHANGE_RATES_SUCCESS";
export const FETCH_EXCHANGE_RATES_FAILED = "FETCH_EXCHANGE_RATES_FAILED";

