import {

    SHOW_LOADING,
    STOP_LOADING,
    SET_SELECTED_CURRENCIES_SUCCESS,

    FETCH_EXCHANGE_DATA_SUCCESS,
    FETCH_EXCHANGE_DATA_FAILED,
    FETCH_CURRENCIES_DATA_SUCCESS,
    FETCH_CURRENCIES_DATA_FAILED,
    FETCH_EXCHANGE_RATES_FAILED,
    FETCH_EXCHANGE_RATES_SUCCESS,
    
} from '../constants/Exchanges';

const initState = {
    loading: false,
    msg:'',
    success: false,
    failed: false,
    showMessage: false,
    exchangeData: [],
    exchangeDataFetched: false,
    currenciesData: [],
    currenciesDataFetched: false,

    currency: {source: 'CAD', destination: 'USD', quote : 0, sourceAmount: 1.0, destinationAmount: 0.0},
    exchangeRates: {},

}

const exchanges = (state = initState, action)=>{
    switch(action.type){

        case FETCH_EXCHANGE_DATA_FAILED:
            return {...state, loading: false, msg: action.message, exchangeDataFetched: false}

        case FETCH_EXCHANGE_DATA_SUCCESS:
            return {...state, loading: false, exchangeData: action.payload, success: true, exchangeDataFetched: true}
        
        case FETCH_CURRENCIES_DATA_FAILED:
            return {...state, loading: false, msg: action.message, currenciesDataFetched:false}
    
        case FETCH_CURRENCIES_DATA_SUCCESS:
            return {...state, loading: false, currenciesData: action.payload, success: true, currenciesDataFetched:true}
    

        case FETCH_EXCHANGE_RATES_FAILED:
            return {...state, loading: false, msg: action.message}
        
        case FETCH_EXCHANGE_RATES_SUCCESS:
            return {...state, loading: false, exchangeRates: action.payload, success: true}

        case SET_SELECTED_CURRENCIES_SUCCESS:
            return {...state, loading: false, currency: action.payload, success: true}

        case SHOW_LOADING:
            return {...state, loading: false}

        case STOP_LOADING:
            return {...state, loading:false}

        default:
            return state;

    }
}


export default exchanges;
    