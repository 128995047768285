import fetch from './auth/currencyLayerFetchInterceptor';
import { CURRENCY_LAYER_API_PATH, CURRENCY_LAYER_CONVERSION_API_PATH } from "../configs/AppConfig";
import { CURRENCY_LAYER_ACCESS_KEY } from "../redux/constants/Auth"


const currencyLayerService = {};

currencyLayerService.getPost = async function (params) {
    const response = await fetch({
        url: CURRENCY_LAYER_API_PATH,
        method: 'get',
        params: {...params, access_key: CURRENCY_LAYER_ACCESS_KEY }
    });

    return response
}



currencyLayerService.getPostConvert = async function (params) {
    const response = await fetch({
        url: CURRENCY_LAYER_CONVERSION_API_PATH,
        method: 'get',
        params: {...params, access_key: CURRENCY_LAYER_ACCESS_KEY }
    });
    return response;
}

currencyLayerService.setPost = function (data) {
    return fetch({
        url: CURRENCY_LAYER_API_PATH,
        method: 'post',
        data: {...data, access_key: CURRENCY_LAYER_ACCESS_KEY}
    });
}


export default currencyLayerService;