import {

SET_SELECTED_CURRENCIES,
SET_SELECTED_CURRENCIES_SUCCESS,


FETCH_EXCHANGE_DATA,
FETCH_EXCHANGE_DATA_SUCCESS,
FETCH_EXCHANGE_DATA_FAILED,
FETCH_CURRENCIES_DATA,
FETCH_CURRENCIES_DATA_SUCCESS,
FETCH_CURRENCIES_DATA_FAILED,

FETCH_EXCHANGE_RATES_SUCCESS,
FETCH_EXCHANGE_RATES_FAILED,
FETCH_EXCHANGE_RATES,


} from '../constants/Exchanges';


export const fetchExchangeData = (payload) =>{
    return {
        type: FETCH_EXCHANGE_DATA,
        payload
    }
}

export const fetchExchangeDataSuccess =(payload) =>{
    return {
        type: FETCH_EXCHANGE_DATA_SUCCESS,
        payload
    }
}

export const fetchExchangeDataFailed =(message)=>{
    return {
        type: FETCH_EXCHANGE_DATA_FAILED,
        message
    }
}


export const fetchCurrencyData = (payload) =>{
    return {
        type: FETCH_CURRENCIES_DATA,
        payload
    }
}

export const fetchCurrencyDataSuccess =(payload) =>{
    return {
        type: FETCH_CURRENCIES_DATA_SUCCESS,
        payload
    }
}

export const fetchCurrencyDataFailed =(message)=>{
    return {
        type: FETCH_CURRENCIES_DATA_FAILED,
        message
    }
}


export const fetchExchangeRates = (payload) =>{
    
    return {
        type: FETCH_EXCHANGE_RATES,
        payload
    }
}

export const fetchExchangeRatesSuccess =(payload) =>{
    return {
        type: FETCH_EXCHANGE_RATES_SUCCESS,
        payload
    }
}

export const fetchExchangeRatesFailed =(message)=>{
    return {
        type: FETCH_EXCHANGE_RATES_FAILED,
        message
    }
}

export const setSelectedCurrencies = (payload)=>{
    return {
        type: SET_SELECTED_CURRENCIES,
        payload
    }
}

export const setSelectedCurrenciesSuccess = (payload)=>{
    return {
        type: SET_SELECTED_CURRENCIES_SUCCESS,
        payload
    }
}

