import axios from 'axios';
import {CURRENCY_LAYER_API_URL} from '../../configs/AppConfig';
import redirect from "../../redirect";

const service = axios.create({
    baseURL: CURRENCY_LAYER_API_URL,
    timeout:60000
});

//config 
const ENTRY_ROUTE = '/'


// API Request interceptor
service.interceptors.request.use(config =>{

    // implement headers here
    return config;

}, error =>{
    console.log('Error')
    Promise.reject(error);
});

//API response intercepto
service.interceptors.response.use((response)=>{
    return response.data
}, (error) =>{
    
    // Remove and redirect
    if (error.response.status === 400 || error.response.status === 403) {
		redirect.redirect(ENTRY_ROUTE)
        console.log('Authentication Fail');
		window.location.reload();
	}

    if (error.response.status === 404) {
		console.log('Not Found');
	}

	if (error.response.status === 500) {
		console.log('Internal Server Error');
	}
	
	if (error.response.status === 508) {
		console.log('Time Out');
	}
    return Promise.reject(error);
});


export default service;
