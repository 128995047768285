import {all, takeEvery, put, fork, call} from 'redux-saga/effects';
import _ from 'lodash';

import {
    SET_SELECTED_CURRENCIES,
    FETCH_CURRENCIES_DATA, 
    FETCH_EXCHANGE_RATES
} from "../constants/Exchanges";

import {
    fetchCurrencyDataFailed,
    fetchCurrencyDataSuccess,
    fetchExchangeRatesFailed,
    fetchExchangeRatesSuccess,
    setSelectedCurrenciesSuccess
} from "../actions/Exchanges";

import LocalService  from '../../services/localService';
import CurrencyLayerService from '../../services/currencyLayerService';


export function* fetchCurrencyDataFromFile(){
    yield takeEvery(FETCH_CURRENCIES_DATA, function*({payload}){
        try{
            const result = yield call(LocalService.getCurrenciesFromFile);
            yield put(fetchCurrencyDataSuccess(result));

        }catch(error){
            //console.log(error);
            yield put(fetchCurrencyDataFailed(error));
        }
    })
}

export function* fetchExchangeRatesFromApi(){
    yield takeEvery(FETCH_EXCHANGE_RATES, function*({payload}){
        try{
            let result = yield call(CurrencyLayerService.getPost, payload);
            result = {
                ...result,
                quotes:  _.map(result.quotes, (val, key)=>{
                                return {"pair": key, "quote": val};
                            })
            }

            //console.log(result);

            yield put(fetchExchangeRatesSuccess(result));
        }catch(error){
            yield put(fetchExchangeRatesFailed(error));
        }
    })
}

export function* setSelectedCurrency(){
    yield takeEvery(SET_SELECTED_CURRENCIES, function*({payload}){
        let result = {...payload, destinationAmount: _.round(_.round(payload.quote * payload.sourceAmount, 7), 6)}
        yield put(setSelectedCurrenciesSuccess(result));
    });
}


export default function* rootSaga(){
    yield all([
        fork(fetchCurrencyDataFromFile),
        fork(fetchExchangeRatesFromApi),
        fork(setSelectedCurrency),
    ]);
}