
export const TRANSLATIONS_FR = {

    "aboutus":{
        "header": "À propos de nous",
        "description": "Nsawo Finance Inc. (numéro d'entreprise 703066738) en bref Nsawo est une filiale et une marque de commerce en instance d'enregistrement de Paytimeafrica Inc. (numéro d'entreprise 768086084) qui est une entreprise de services monétaires (ESM) enregistrée auprès du Centre d'analyse des opérations et déclarations financières du Canada (CANAFE). ) sous le numéro d'enregistrement M20196957 dans la section de change et de transfert de fonds.",
        "core-values": {
            "header": "Nos valeurs fondamentales",
            "our-family": {
                "header": "Notre famille",
                "description": "Nous sommes fiers de nous soucier des intérêts des membres de notre famille et de servir les intérêts de notre plateforme, de nos parties prenantes et de nos partenaires"
            },
            "our-innovation": {
                "header": "Notre innovation",
                "description": "L'engagement envers une amélioration constante et sans fin de nos produits, systèmes, processus et personnes reste la pierre angulaire de notre stratégie commerciale"
            },
            "our-integrity": {
                "header": "Notre intégrité",
                "description": "Nous sommes transparents, éthiques, honnêtes et professionnels dans toutes nos interactions"
            },
            "our-excellence": {
                "header": "Notre excellence",
                "description": "Notre désir d'être responsable et de fournir le meilleur pour notre communauté est essentiel, l'héritage de notre plateforme restera fort"
            }
        },
        "our-vision": {
            "header": "Notre vision",
            "description": "Être la principale plateforme numérique de distribution de produits et services financiers destinés aux personnes d'ascendance africaine et à la communauté noire dans le monde entier"
        },
        "our-mission": {
            "header": "Notre mission",
            "description": "Notre mission est de donner aux familles des personnes d'ascendance africaine et à la communauté noire du monde entier des produits et services financiers qui améliorent leur vie grâce à notre plateforme fintech."
        },
        "leadership": {
            "header": "La direction de Nsawo",
            "description": "Rencontrez l'équipe Nsawo Finance Inc C qui sort des sentiers battus pour faire des communautés noires un meilleur endroit pour la liberté financière"
        }
    },
    "seo": {
        "home": {
            "title": "Nsawo - La plateforme financière gratuite des sans frontières",
            "description": "Un immigrant! êtes-vous ? oui. Des transferts transfrontaliers à l'assurance et aux investissements, l'application Nsawo facilite le transfert d'argent ou l'envoi, le paiement, la demande, la réception, l'échange de monnaies fiduciaires et cryptographiques dans le monde entier à une micro-fraction d'un coût.",
        },
        "aboutus":{
            "title": "À propos de nous",
            "description": "Nsawo Finance Inc. (numéro d'entreprise 703066738) en bref Nsawo est une filiale et une marque de commerce en instance d'enregistrement de Paytimeafrica Inc. (numéro d'entreprise 768086084) qui est une entreprise de services monétaires (ESM) enregistrée auprès du Centre d'analyse des opérations et déclarations financières du Canada (CANAFE). ) sous le numéro d'enregistrement M20196957 dans la section de change et de transfert de fonds.",
        }
    },
    "header": {
        "menus": {
            "features":'Fonctionnalités',
            "banking": 'Bancaire',
            "company": 'Compagnie',
            "business": 'For Business',
            "help": 'Aide',
        },
        "submenus":{
            "add": "Ajouter de l'argent",
            "request": "Demande de l'argent",
            "send": "Envoyer de l'argent",
            "receive": "Recevoir de l'argent",
            "exchange": "Échange de devises",
            "bill-payments": "Payer les factures",
            "airtime": "Acheter du temps d'antenne",
            "vcard": "Carte virtuelle",
            "pay-school-fees": "Payer les frais de scolarité",
            "request_statement":"Demande de relevé",
            "referal":"Parrainez et gagnez (programme de parrainage)",
            "save": "Sauvegarder",
            "credit": "Crédit",
            "budget-planner": "Planificateur de budget",
            "insurance": "Assurance",
            "investment": "Investissement",
            "business_account": "Business Account",
            "business_card": "Platinum Card",
            "business_payouts": "Customer Payouts",
            "business_pricing": "Pricing",
            "blog": "Blog",
            "press": "dans la presse",
            "join-our-team": "Rejoins notre équipe",
            "investors": "Investisseuses",
            "about-us": "À propos de nous",
            "get-help": "Obtenir de l'aide",
            "faqs": "FAQs",
            "guides": "Guides",
            "contact-us" : "Nous contacter",
        }
    },
    "section": {
        "notification-header": "Transferts hybrides sécurisés, rapides, flexibles et fiables",
        "notification-subheader": "De confiance",
        "header": "Sans frontières. Sans fin, sans contact",
        "message" : "Nsawo, une plateforme fintech gratuite pour les immigrants. Nous permettons de payer, envoyer, demander, échanger et recevoir facilement des devises fiduciaires dans le monde entier sur notre plateforme alimentée par la technologie blockchain."
    },

    "getstarted_steps": {
        "header" :"Un moyen rapide de vous lancer.",
        "deposit-money-header": "Ajouter de l'argent",
        "deposit-money-description": "Ajoutez facilement des fonds à votre portefeuille via plusieurs canaux tels que VISA, MasterCard, American Express, Discover, compte bancaire ainsi que Mobile Money dans les pays africains",
        "send-money-header": "Envoyer de l'argent",
        "send-money-description": "Transferts d'argent instantanés gratuits entre les portefeuilles Nsawo dans le monde",
        "exchange-money-header": "Monnaie d'échange",
        "exchange-money-description": "Profitez d'un portefeuille électronique multidevises sans frontières qui vous permet d'échanger, de dépenser et d'envoyer de l'argent au meilleur taux du marché de gros.",
        "pay-utility-header": "Payer les services publics",
        "pay-utility-description": "Payez facilement les services publics à travers les frontières à votre convenance",
        "inbox-header": "Boîte de réception d'activité",
        "inbox-description": "Toutes vos activités récentes, derniers dépôts, échanges, tous les transferts et messages de chat directs avec vos connexions - tout à votre coup d'œil dans l'application."
    },


    "features": {
        "header": "Nous sommes futuristes et compétitifs.",
        "group-sending-header": "Envoi groupé",
        "group-sending-description": "Profitez de la fonction d'envoi de groupe imbattable, créez facilement des groupes de votre choix et envoyez de l'argent à vos groupes affiliés tels que des associations, des groupes de la société, des groupes familiaux, des groupes religieux - le tout gratuitement",
        "chat-payment-header": "Paiements par chat",
        "chat-payment-description": "Nous sommes une communauté noire en évolution financière et une plate-forme interactive. Envoyez, payez, demandez et recevez de l'argent facilement via notre service de paiement par chat p2p avec vos amis, vos proches et votre famille - gratuitement",
        "activity-inbox-header": "Boîte de réception d'activité",
        "activity-inbox-description": "Toutes vos activités récentes, derniers dépôts, échanges, tous les transferts et messages de chat directs avec vos connexions - tout à votre coup d'œil dans l'application.",
        "cards-payment-header": "Connexion Essence",
        "cards-payment-description": "Connectez-vous avec nos cartes de débit et virtuelles sans frontières Essence 2FA pour faciliter votre expérience de magasinage en ligne au bout de vos doigts. Invitez vos proches et votre famille à profiter des avantages de la carte essence - payez plus facilement que jamais.",
        "gift-cards-header": "Cartes cadeaux",
        "gift-cards-description": "Vous souhaitez faire un don ou une contribution à l'église avec un reçu fiscal ? Nos cartes-cadeaux Essence vous protègent. ne cherchez plus, vous pouvez maintenant acheter une carte-cadeau de n'importe quel montant dans n'importe quelle devise et l'envoyer. - acheter, envoyer, approuver et échanger à tout moment partout.",
        "payment-vouchers-header": "Bons de paiement",
        "payment-vouchers-description": "Connectez-vous avec le service Essence 2FA Voucher pour envoyer ou recevoir des paiements vers et depuis des tiers comme les plateformes de trading Neobanks, Securities & Forex et bien d'autres - payez facilement n'importe où avec les cartes de bons de paiement Essence",
        "customer-support-header": "Service client",
        "customer-support-description": "Si vous avez des questions ou avez besoin d'aide, notre équipe de service client est à portée de clic en ligne dans l'application - disponible en ligne 24h / 24 et 7j / 7",
    
    },

    "banking": {
        "personal-save-header": "Personal Savings",
        "personal-save-description": "",
        "group-save-header": "Group Savings",
        "group-save-description": "",
        "personal-credit-header": "Personal Credit",
        "personal-credit-description": "",
        "group-credit-header": "Group Credit",
        "group-credit-description": ""
    },

    "partners": {
        "header1" : "Nos partenaires",
        "header2" : "Facilitateur technologique",
        "header3" : "mondial",
        "message1" : "Envoyez, recevez, échangez et négociez des monnaies fiduciaires et cryptographiques où que vous soyez avec",
        "message2" : "Nsawo",
        "message3" : "propulsé par un réseau de valeur perturbateur de catalyseurs de blockchain.",
    },

    "testimonials": {
        "header1" : "Savez-vous ?",
        "header2": "Plus de 1000",
        "header3": "personnes nous aiment",
        "message1": "Rejoignez plus d'un million de personnes envoyant, recevant et échangeant de l'argent en toute sécurité dans le monde entier."
    },

    "joinus": {
        "header1" : "Tu veux nous rendre fiers",
        "message1": "Rejoignez une équipe dont le but ultime est de débloquer des opportunités mondiales pour les Africains et la communauté des peuples noirs.",
        "message2": "Rejoins l'équipe",
    },

    "appstore": {
        "header1" : "Disponible pour votre",
        "header2" : "Smartphones",
        "message" : "Vous êtes à quelques pas pour commencer à profiter de nos services et avantages monétaires.",
        "appstore_message" : "Téléchargez notre application sur Apple ou Google Store",
        "install_message" : "Installez l'application maintenant sur vos téléphones portables",
        "link_message" : "Apprendre encore plus",
        "early_access_join_message_text": "For early access to Nsawo financial platform",
        "early_access_join_text": "Sign up Now"
    },

    "coverage": {
        "header1" : "Profitez de sans frontières, sans contact",
        "header2" : "transferts d'argent.",
        "subheader" : "Nsawo est basé sur Afrocentric mais ouvert à tout le monde dans le monde. ",
        "features" : {
            "countries" :  " Plus d'options pour les pays d'accueil",
            "payout_methods" : " Plus de méthodes de paiement",
            "exchage_rates" : " De meilleurs taux de change",
            "instant_transfer" : " Transferts personnels instantanés",
            "giftcards" : " Envoyez de l'argent via des cartes-cadeaux électroniques",
         },
         "buttonText" : "Apprendre encore plus"
    },

    "footer": {
        "menus": {
            "products": 'Des produits',
            "company": 'Compagnie',
            "help": 'Aider',
            "compliancy": 'Conformité',
        },
        "submenus": {
            "add": "Ajouter de l'argent",
            "send": "Envoyer de l'argent",
            "exchange": "Échange",
            "bill-payment": "Masse salariale",
            "pay-school-fees": "Payer les frais de scolarité",
            "blog": "Blogs",
            "press": "Presse",
            "join-our-team": "Carrières",
            "investors": "Investisseurs",
            "about-us": "À propos de nous",
            "get-help": "Obtenir de l'aide",
            "faqs": "FAQs",
            "guides": "Guides",
            "terms-conditions": "termes et conditions",
            "privacy-policy": "Politique de confidentialité",
            "cookie-policy" : "Politique relative aux cookies",
        },
        "headers": {

            "contact": "Contacter",
        },
        "copyright":{

            "trademark_statement1": `Nsawo est le nom commercial de Paytimeafrica Inc, qui est une entreprise de services monétaires (MSB) enregistrée auprès du Centre d'analyse des transactions et des déclarations financières du Canada (CANAFE) sous les numéros d'enregistrement M 20196957 sous change, transfert de fonds et devises numériques.`,
            "trademark_statement2" : "Rejoignez notre famille Nsawo en téléchargeant l'application Nsawo qui dispose d'un portefeuille multi-devises avec des avantages qui incluent des transferts gratuits de Nsawo à Nsawo.",
            "trademark_statement3": "Slogan : Tous vos besoins de transfert de fonds sont satisfaits avec notre application Nsawo. Profitez des avantages des transferts d'argent sécurisés, gratuits et instantanés avec cette plateforme fintech afrocentrique canadienne.",
            "trademark_statement4": "Inscrivez-vous pour un accès anticipé à Nsawo, une plate-forme fintech conçue pour les personnes d'ascendance africaine et tout le monde partout dans le monde. Vous pouvez envoyer de l'argent, demander et recevoir de l'argent ainsi que d'autres fonctionnalités en fonction de votre pays d'origine.",
            "trademark_statement5": "* La carte prépayée Visa / débit MasterCard est détenue et émise par DC Bank en vertu d'une licence de Visa International ou de MasterCard international incorporé. L'utilisation de la carte sera régie par l'accord en vertu duquel elle sera émise. Les marques Visa et MasterCard sont des marques déposées de Visa International et MasterCard international incorporé. Tous les crédits et approbations seront fournis par Paytimeafrica Inc. DC Bank ne fournit aucun crédit ni prêt. Tous les financements et prêts pour ce programme seront fournis par Paytimeafrica Inc. o/a Nsawo. Les approbations sont soumises aux directives de révision et de souscription de Paytimeafrica Inc.",
            
            "trademark_statement6": "* Il n'y a pas de frais pour postuler. Des frais mensuels, des frais de transaction et des intérêts s'appliquent et sont divulgués dans les conditions générales de la carte prépayée.",
            "trademark_statement7": "En utilisant ce site Web, vous comprenez que les informations présentées sont fournies à titre informatif uniquement et acceptez nos conditions générales ainsi que notre politique de confidentialité.",

            
        }
    },
}