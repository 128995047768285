
export const TRANSLATIONS_EN = {

    "aboutus":{
        "header": "About Nsawo",
        "description": "Nsawo Finance Inc. (Business Number 703066738) in short Nsawo is a subsidiary and pending trademark registration of Paytimeafrica Inc. (Business Number 768086084) which is a registered Money Services Business (MSB) with the Financial Transaction and Reports Analysis Centre of Canada (FINTRAC) under registration number M20196957 in the currency exchange and funds transfer section.",
        "core-values": {
            "header": "Our Core Values",
            "our-family": {
                "header": "Our Family",
                "description": "We pride ourselves in caring about the interests of our family members as well as serving the interests of our platform, stakeholders and partners"
            },
            "our-innovation": {
                "header": "Our Innovation",
                "description": "The commitment to a constant and never-ending improvement in our products, systems, processes and people remains the cornerstone of our business strategy"
            },
            "our-integrity": {
                "header": "Our Integrity",
                "description": "We are transparent, ethical, honest and professional in all our interactions"
            },
            "our-excellence": {
                "header": "Our Excellence",
                "description": "Our desire to be accountable and provide the best for our community is key, the legacy of our platform will stay strong"
            }
        },
        "our-vision": {
            "header": "Our Vision",
            "description": "To be the leading digital platform distributing financial products and services geared to people of African descent and the black community all over the world"
        },
        "our-mission": {
            "header": "Our Mision",
            "description": "Our mission is to empower the families of people of African descent and the black community around the world with financial products and services that elevate their lives through our fintech platform."
        },
        "leadership": {
            "header": "The Nsawo Leadership",
            "description": "Meet with Nsawo Finance Inc C team that think out of the box to make the blacks communities a better place for financial freedom "
        }
    },

    "seo": {
        "home": {
            "title": "Nsawo - The free borderless immigrants financial platform",
            "description": "An immigrant! are you ? yes. From cross-border transfers to insurance and investments, Nsawo app makes it easy to move money or send, pay, request, receive, exchange fiat and crypto currencies globally at a micro fraction of a cost.",
        },
        "aboutus":{
            "title": "About Nsawo",
            "description": "Nsawo Finance Inc. (Business Number 703066738) in short Nsawo is a subsidiary and pending trademark registration of Paytimeafrica Inc. (Business Number 768086084) which is a registered Money Services Business (MSB) with the Financial Transaction and Reports Analysis Centre of Canada (FINTRAC) under registration number M20196957 in the currency exchange and funds transfer section.",
        }
    },
    
    "header": {
        "menus": {
            "features":'Features',
            "banking": 'Banking',
            "business": 'For Business',
            "company": 'Company',
            "help": 'Help',
        },
        "submenus":{
            "add": "Add Money",
            "request": "Request Money",
            "send": "Send Money",
            "receive": "Receive Money",
            "exchange": "Exchange Money",
            "bill-payments": "Pay Bills",
            "airtime": "Buy Airtime",
            "vcard": "Cards",
            "pay-school-fees": "Pay School Fees / Tuition",
            "request_statement":"Statement Request",
            "referal":"Refer and Earn",
            "save": "Save",
            "credit": "Credit",
            "budget-planner": "Budget Planner",
            "insurance": "Insurance",
            "investment": "Investment",
            "business_account": "Business Account",
            "business_card": "Platinum Card",
            "business_payouts": "Customer Payouts",
            "business_pricing": "Pricing",
            "blog": "Blog",
            "press": "in the Press",
            "join-our-team": "Join Our Team",
            "investors": "Investors",
            "about-us": "About Us",
            "get-help": "Help Centre",
            "faqs": "FAQs",
            "guides": "Guides",
            "contact-us" : "Contact Us",
        }
    },

    "section": {
        "notification-header": "secure, fast, flexible and reliable Hybrid transfers",
        "notification-subheader": "Trusted",
        "header": "Borderless. Endless, Contactless ",
        "message" : "Nsawo, a free immigrants fintech platform. We make it possible to easily pay, send, request, exchange and receive fiat currencies globally on our platform powered by blockchain technology."
    },
    "getstarted_steps": {
        "header" :"A quick way to get you started.",
        "deposit-money-header": "Add money",
        "deposit-money-description": "Easily add funds to your wallet through multiple channels like VISA, MasterCard, American Express, Discover, Bank Account as well as Mobile Money in African countries",
        "send-money-header": "Send money",
        "send-money-description": "Free instant money transfers between Nsawo wallets globally",
        "exchange-money-header": "Exchange money",
        "exchange-money-description": "Enjoy a borderless multi-currency e-wallet that allows you to exchange, spend and send money at the best wholesale market rate.",
        "pay-utility-header": "Pay utilities",
        "pay-utility-description": "Pay utilities with ease across boarders at your convenience",
        
    },

    "features": {
        "header": "We are futuristic and competitive.",
        "group-sending-header": "Group Sending",
        "group-sending-description": "Enjoy the unbeatable group sending feature, Easily create groups of your choice and send money to your affiliate groups like associations, society groups, family groups, church groups - all for free",
        "chat-payment-header": "Chat payments",
        "chat-payment-description": "We are a financial evolving blacks community and interactive platform, Easily send, pay, request and receive money through our p2p chat payment service with your friends, loved ones and family  -  for free",
        "activity-inbox-header": "Activity Inbox",
        "activity-inbox-description": "All your recent activities, latest deposits, exchanges, all transfers and direct chat messages with your connections - everything at your glance wthin the app.",
        "cards-payment-header": "Essence Connection",
        "cards-payment-description": "Connect with our Essence 2FA borderless debit and virtual cards to ease your online shopping experience at your finger tip. Invite your relatives and family to enjoy the benefits of essence card  - pay more easily than ever before.",
        "gift-cards-header": "Gift Cards",
        "gift-cards-description": "Do you want to make a donation or a church contribution with atax receipt ? Our Essence Gift cards have got your back covered. look no further you can now buy a gift card of any amount in any currency and send it over. - buy, send, approve and redeem anytime everywhere.",
        "payment-vouchers-header": "Payment Vouchers",
        "payment-vouchers-description": "Connect with Essence 2FA Voucher service to send or receive payments to and from third parties like Neobanks, Securities & Forex trading platforms and many more  - pay easily anywhere with Essence payment voucher cards",

        "customer-support-header": "Customer Support",
        "customer-support-description": "If you have any question or need help, our customer service team is just a click away online within the app - available online 24/7.",
    },

    "banking": {

        "save-header": "Banking - Save ",
        "save-description": "Nsawo - Your all in one AI financial platform that helps you plan and save for a balanced financial life style, enjoy the unlimited potential of our personal and group savings plans with the best ever assured returns ",
        "save-sub-header": "Easily bank and save with us reliably",

        "credit-header": "Banking - Credit ",
        "credit-description": "Our all in one AI financial platform connects you with a secured, affordable digital line of credit network to empower your business ideas and other financial goals for a balanced financial life style, unlock the unlimited potential of our personal and group credit products at as low as 6.5% APR ",
        "credit-sub-header": "Enjoy an affordable line of credit at your finger tip",
        

        "personal-save-header": "Personal Savings",
        "personal-save-description": "We help you have a balanced saving culture with our unbeatable personal saving plans geared for your future financial needs. plan and save at ease  ",

        "personal-save-products": {
            "goal-header": "Life Goal",
            "goal-description": "Helps you to plan for future needs, your goals can be big or small – it depends on where you are at in your life and what you want to achieve",
            "guaranteed-header": "Guaranteed",
            "guaranteed-description": "Aims to help you achieve your long-term life goals by offering you a secure insurance cover against eventualities and creating an assured return on your savings with the best rates ever ",
            "recurring-header": "Recurring",
            "recurring-description": "An automatic investment plan that allows you to contribute a fixed amount at regular intervals to your savings account for future needs, promotes a regular savings culture on Nsawo financial network.",

            
        },
        "group-save-header": "Group Savings",
        "group-save-description": "Save for your community groups like never before, we provide the best group saving plans and assured return on investment rates,  experience our intelligent saving plan recommendation service powered by an AI and ML engine",

        "personal-credit-header": "Personal Credit",
        "personal-credit-description": "Our personal lines of credit can be used for your own expenses, business or home costs. You borrow a set amount of money, but draw only what you need and pay interest only on the amount you use",

        "personal-credit-products": {
            "debt-consolidation-credit-header": "Debt consolidation credit",
            "debt-consolidation-credit-description": "A debt consolidation credit is a personal credit intended to pay off all of your debts at once. We give you a single credit with lower interest rates to pay off your many debts, we offer simple and easy daily, weekly or monthly repayment plans",
            "personal-business-credit-header": "Personal business credit",
            "personal-business-credit-description": "Intended for small business to cover inventory or un expected expenses. The credit plan amount you receive is calculated by our AI credit score recommendation service which depends on your transaction volumes on Nsawo financial network as well as the evaluated business net worth",
            "personal-home-credit-header": "Home improvement credit",
            "personal-home-credit-description": "You can use our improvement line of credit for home renovation or repairs. The  credit plan amount you can receive is determined by the our AI credit score recommendation service, equity in your home.",
            "personal-education-credit-header": "Education credit",
            "personal-education-credit-description": "An education line of credit helps you pay for expenses related to education, we offer flexible options which provide financial assistance you need with tuition, living expenses and study materials.",
            "personal-car-credit-header": "Car credit",
            "personal-car-credit-description": "Finally, time to upgrade your car? We'll help you with the best car credit for you based on your credit score profile at an affordable interest rate.",
        },

        "group-credit-header": "Group credit",
        "group-credit-description": "Are you a business group, community looking for a business line of credit ? Our group credit plan has got you covered with the best and affordable interest rates ever. create a group on Nsawo financial network and redeem your line of credit",

        "saving-rates-sections": {
            "header":"Account Charges",
            "life-goals": {
                "1": " Free monthly account management fees",
                "2": " Free account application fees",
                "3": " 0% monthly growth interest rate",
                "4": " Non taxable on withdraw to wallets ",
                
            },

            "guaranteed": {
                "1": " CAD $10 monthly account management fees",
                "2": " Free account application fees",
                "3": " 6% - 10% monthly growth interest rate",
                "4": " 4% - tax on interests withdraw to wallets",
                
            },
            "recurring": {
                "1": " Free monthly account management fees",
                "2": " Free account application fees",
                "3": " 0% monthly growth interest rate",
                "4": " Non taxable on withdraw to wallets ",
                
            },

            "groups": {
                "1": " CAD $20 monthly account management fees",
                "2": " Free account application fees",
                "3": " 6% - 10% monthly growth interest rate",
                "4": " 4% - tax on interests withdraw to wallets",
                
            }
        },

        "credit-rates-sections": {
            "header":"Credit Rates & Charges",
            "debt-consolidation": {
                "1": " No application fees",
                "2": " credit amount up to CAD $50,000",
                "3": " 10% - 19.9% APR interest rate",
                "4": " 4% credit insurance fees",
                "5": " 10 - 36 months credit tenancy",
                
            },

            "personal-business": {
                "1": " No application fees",
                "2": " credit amount up to CAD $50,000",
                "3": " 10% - 19.9% APR interest rate",
                "4": " 4% credit insurance fees",
                "5": " 10 - 36 months credit tenancy",
                
            },
            "personal-home": {
                "1": " No application fees",
                "2": " credit amount up to CAD $50,000",
                "3": " 10% - 19.9% APR interest rate",
                "4": " 4% credit insurance fees",
                "5": " 10 - 36 months credit tenancy",
                
            },

            "personal-education": {
                "1": " No application fees",
                "2": " credit amount up to CAD $50,000",
                "3": " 10% - 19.9% APR interest rate",
                "4": " 4% credit insurance fees",
                "5": " 10 - 36 months credit tenancy",
                
            },

            "personal-car": {
                "1": " No application fees",
                "2": " credit amount up to CAD $50,000",
                "3": " 10% - 19.9% APR interest rate",
                "4": " 4% credit insurance fees",
                "5": " 10 - 36 months credit tenancy",
                
            },
            "groups": {
                "1": " CAD $500.00 application fees, >= CAD$10,000 AGR (Average Group Revenue), 20 Min group members,",
                "2": " credit amount up to CAD $100,000",
                "3": " 19.9% - 29.9% APR interest rate",
                "4": " 6% credit insurance fees",
                "5": " 12 - 48 months credit tenancy",
                
            },

        }
    },

    "partners": {
        "header1" : "Our Partners",
        "header2": "Global Technology",
        "header3": "Enablers",
        "message1": "Send, receive, exchange & trade fiat and crypto currencies on the go with",
        "message2": "Nsawo",
        "message3": "powered by a disruptive value network of blockchain enablers.",
    },

    "testimonials": {
        "header1" : "Do you know ?",
        "header2": "1,000+",
        "header3": "people are loving Us",
        "message1": "Join over 1 million people securely sending, receiving, exchanging money globally."
    },

    "joinus": {
        "header1" : "You want to make us proud",
        "message1": "Join a team with the ultimate goal to to unlock global opportunies for Africans and black peoples community",
        "message2": "Join the team",
    },

    "appstore": {
        "header1": "Available for your",
        "header2": "Smartphones",
        "message": "You are a few steps to start enjoying our money services & benefits.",
        "appstore_message": "Download our app from Apple or Google store",
        "install_message": "Install app now on your cellphones",
        "link_message": "Learn more",
        "early_access_join_message_text": "For early access to Nsawo financial platform",
        "early_access_join_text": "Sign up Now"
    },

    "coverage": {
        "header1": "Enjoy borderless, contactless",
        "header2": "money transfers.",
        "subheader": "Nsawo is Afrocentric based though open to everyone globally.",
        "features": {
           "countries":  " More options for receiving countries",
           "payout_methods": " More payout methods",
           "exchage_rates": " Better exchange rates",
           "instant_transfer": " Instant personal transfers",
           "giftcards": " Send money via e gift cards",
        },
        "buttonText": "Learn More"
    },


    "footer": {
        "menus": {
            "products":'Products',
            "company": 'Company',
            "help": 'Help',
            "compliancy": 'Compliancy',
        },
        "submenus": {
            "add": "Add Money",
            "send": "Send Money",
            "exchange": "Exchange",
            "bill-payment": "Pay Bills",
            "pay-school-fees": "Pay School Fees",
            "blog": "Blogs",
            "press": "Press",
            "join-our-team": "Join Our Team",
            "investors": "Investors",
            "about-us": "About Us",
            "get-help": "Get Help",
            "faqs": "FAQs",
            "guides": "Guides",
            "terms-conditions": "Terms & Conditions",
            "privacy-policy": "Privacy Policy",
            "cookie-policy" : "Cookie Policy ",
        },

        "headers": {
            "contact": "Contact",
        },

        "copyright":{

            "trademark_statement1": "Nsawo is the trading name of Paytimeafrica Inc which is a registered Money Services Business( MSB) with the Financial Transaction And Reports Analysis Centre of Canada (FINTRAC) under registration numbers M 20196957 under currency exchange, funds transfer and digital currencies.",
            "trademark_statement2": "Join our Nsawo family by downloading the Nsawo app which boasts a multi currency wallet with benefits which include free Nsawo to Nsawo transfers.",
            "trademark_statement3": "Free borderless immigrants financial platform.  Enjoy the benefits of secure free and instant money transfers with this Canadian Afrocentric fintech platform.",
            "trademark_statement4": "Sign up for early access to Nsawo, a fintech platform designed for people of African descent and everyone all over the world. You can send money, request and receive money plus other features according to your home country.",
            "trademark_statement5": "* The Pre-Paid Visa / debit MasterCard is owned and issued by DC Bank pursuant to a license from Visa International or MasterCard international incorporated Use of the card will be governed by the agreement under which it will be issued. The Visa and MasterCard Brands are registered trademarks of Visa International and MasterCard international incorporated. All credit and approvals will be provided by Paytimeafrica Inc. DC Bank provides no credit or loans. All funding and lending for this program will be provided by Paytimeafrica Inc. o/a Nsawo. Approvals are subject to Paytimeafrica Inc.’s review and underwriting guidelines.",
            
            "trademark_statement6": "* There is no cost to apply. Monthly fees, transactional fees, and interest apply and are disclosed in the Pre-Paid Card Terms and Conditions.",
            "trademark_statement7": "By using this website, you understand the information being presented is provided for informational purposes only and agree to our Terms and Conditions as well as our Privacy Policy.",

            
        }
    },
}