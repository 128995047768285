
import _ from 'lodash';
import currencies from "../data/currencies.json";


const LocalService = {};

LocalService.getCurrenciesFromFile = function (){
    const data =   _.map(currencies.currencies, (val, key)=>{
        return {"currency": key, "country": val};
     });
     return data;
}

export default LocalService;