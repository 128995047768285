import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import AOS from "aos";
import "aos/dist/aos.css";

import "./App.scss";
import Layout from "./components/Layout/";
import {
  Route,
  Routes,
} from "react-router-dom";
import withRouter from "./components/Router";

import { 
  Home,
  AboutUs,
  Add,
  Send,
  Exchange,
  PayBills,
  Cards,
  PayFees,
  Vouchers,
  Giftcards,
  Save,
  Credit,
  Faqs
} from "./routes";

class App extends Component {
  componentDidMount() {
    AOS.init({
      duration: 20,
    });
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
 
  render() {
    return (
      <React.Fragment>
        <Provider store={store}>
          <Suspense fallback={this.Loader()}>
              <Routes>
                <Route  path="/" exact = {true} element={ <Layout><Home/></Layout> } isWithoutLayout = {true}/>
                <Route  path="/index" element={ <Layout><Home/></Layout> } isWithoutLayout = {true}/>
                <Route  path="/aboutus" element={ <Layout><AboutUs/></Layout> }/>
                <Route  path="/add" exact = {true} element={ <Layout><Add/></Layout> }/>
                <Route  path="/send" element={ <Layout><Send/></Layout> }/>
                <Route  path="/exchange" element={ <Layout><Exchange/></Layout> }/>
                <Route  path="/paybills" element={ <Layout><PayBills/></Layout> }/>
                <Route  path="/cards" element={ <Layout><Cards/></Layout> }/>
                <Route  path="/payfees" element={ <Layout><PayFees/></Layout> }/>
                <Route  path="/vouchers" element={ <Layout><Vouchers/></Layout> }/>
                <Route  path="/giftcards" element={ <Layout><Giftcards/></Layout> }/>
                <Route  path="/save" element={ <Layout><Save/></Layout> }/>
                <Route  path="/credit" element={ <Layout><Credit/></Layout> }/>
                <Route  path="/faq" element={ <Layout><Faqs/></Layout> }/>
              </Routes>
          </Suspense>
        </Provider>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
