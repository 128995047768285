import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import moment from  'moment';

import { TRANSLATIONS_EN } from './en/translations';
import { TRANSLATIONS_FR } from './fr/translations';
import { TRANSLATIONS_ES } from './es/translations';


i18next
    .use(LanguageDetector)
    .use(initReactI18next).init({
        interpolation: {
            format: function (value, format, lng) {
                if (value instanceof Date) return moment(value).format(format);
                if (typeof value === "number") return new Intl.NumberFormat().format(value);
                return value;
              }
        },
        resources: {
            en: {
                translation: TRANSLATIONS_EN
            },
            es:{
                translation: TRANSLATIONS_ES
            },

            fr: {
                translation: TRANSLATIONS_FR
            }
        }
    });

export default i18next;